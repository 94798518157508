import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "api"
    }}>{`API`}</h1>
    <p><inlineCode parentName="p">{`react-hooks-testing-library`}</inlineCode>{` exports the following methods:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reference/api#renderhook"
        }}><inlineCode parentName="a">{`renderHook`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reference/api#act"
        }}><inlineCode parentName="a">{`act`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reference/api#cleanup"
        }}><inlineCode parentName="a">{`cleanup`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reference/api#addcleanup"
        }}><inlineCode parentName="a">{`addCleanup`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reference/api#removecleanup"
        }}><inlineCode parentName="a">{`removeCleanup`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reference/api#manually-suppress-output"
        }}><inlineCode parentName="a">{`suppressErrorOutput`}</inlineCode></a></li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "renderhook"
    }}><inlineCode parentName="h2">{`renderHook`}</inlineCode></h2>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`function renderHook(callback: (props?: any) => any, options?: RenderHookOptions): RenderHookResult
`}</code></pre>
    <p>{`Renders a test component that will call the provided `}<inlineCode parentName="p">{`callback`}</inlineCode>{`, including any hooks it calls, every
time it renders.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`renderHook`}</inlineCode>{` function accepts the following arguments:`}</p>
    <h3 {...{
      "id": "callback"
    }}><inlineCode parentName="h3">{`callback`}</inlineCode></h3>
    <p>{`The function that is called each `}<inlineCode parentName="p">{`render`}</inlineCode>{` of the test component. This function should call one or
more hooks for testing.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`props`}</inlineCode>{` passed into the callback will be the `}<inlineCode parentName="p">{`initialProps`}</inlineCode>{` provided in the `}<inlineCode parentName="p">{`options`}</inlineCode>{` to
`}<inlineCode parentName="p">{`renderHook`}</inlineCode>{`, unless new props are provided by a subsequent `}<inlineCode parentName="p">{`rerender`}</inlineCode>{` call.`}</p>
    <h3 {...{
      "id": "options-optional"
    }}><inlineCode parentName="h3">{`options`}</inlineCode>{` (Optional)`}</h3>
    <p>{`An options object to modify the execution of the `}<inlineCode parentName="p">{`callback`}</inlineCode>{` function. See the
`}<a parentName="p" {...{
        "href": "/reference/api#renderhook-options"
      }}><inlineCode parentName="a">{`renderHook`}</inlineCode>{` Options`}</a>{` section for more details.`}</p>
    <h2 {...{
      "id": "renderhook-options"
    }}><inlineCode parentName="h2">{`renderHook`}</inlineCode>{` Options`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`renderHook`}</inlineCode>{` function accepts the following options as the second parameter:`}</p>
    <h3 {...{
      "id": "initialprops"
    }}><inlineCode parentName="h3">{`initialProps`}</inlineCode></h3>
    <p>{`The initial values to pass as `}<inlineCode parentName="p">{`props`}</inlineCode>{` to the `}<inlineCode parentName="p">{`callback`}</inlineCode>{` function of `}<inlineCode parentName="p">{`renderHook`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "wrapper"
    }}><inlineCode parentName="h3">{`wrapper`}</inlineCode></h3>
    <p>{`A React component to wrap the test component in when rendering. This is usually used to add context
providers from `}<inlineCode parentName="p">{`React.createContext`}</inlineCode>{` for the hook to access with `}<inlineCode parentName="p">{`useContext`}</inlineCode>{`. `}<inlineCode parentName="p">{`initialProps`}</inlineCode>{` and
props subsequently set by `}<inlineCode parentName="p">{`rerender`}</inlineCode>{` will be provided to the wrapper.`}</p>
    <h2 {...{
      "id": "renderhook-result"
    }}><inlineCode parentName="h2">{`renderHook`}</inlineCode>{` Result`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`renderHook`}</inlineCode>{` function returns an object that has the following properties:`}</p>
    <h3 {...{
      "id": "result"
    }}><inlineCode parentName="h3">{`result`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`{
  all: Array<any>
  current: any,
  error: Error
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`current`}</inlineCode>{` value of the `}<inlineCode parentName="p">{`result`}</inlineCode>{` will reflect the latest of whatever is returned from the
`}<inlineCode parentName="p">{`callback`}</inlineCode>{` passed to `}<inlineCode parentName="p">{`renderHook`}</inlineCode>{`. Any thrown values from the latest call will be reflected in the
`}<inlineCode parentName="p">{`error`}</inlineCode>{` value of the `}<inlineCode parentName="p">{`result`}</inlineCode>{`. The `}<inlineCode parentName="p">{`all`}</inlineCode>{` value is an array containing all the returns (including the
most recent) from the callback. These could be `}<inlineCode parentName="p">{`result`}</inlineCode>{` or an `}<inlineCode parentName="p">{`error`}</inlineCode>{` depending on what the callback
returned at the time.`}</p>
    <h3 {...{
      "id": "rerender"
    }}><inlineCode parentName="h3">{`rerender`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`function rerender(newProps?: any): void
`}</code></pre>
    <p>{`A function to rerender the test component, causing any hooks to be recalculated. If `}<inlineCode parentName="p">{`newProps`}</inlineCode>{` are
passed, they will replace the `}<inlineCode parentName="p">{`callback`}</inlineCode>{` function's `}<inlineCode parentName="p">{`initialProps`}</inlineCode>{` for subsequent rerenders.`}</p>
    <h3 {...{
      "id": "unmount"
    }}><inlineCode parentName="h3">{`unmount`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`function unmount(): void
`}</code></pre>
    <p>{`A function to unmount the test component. This is commonly used to trigger cleanup effects for
`}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` hooks.`}</p>
    <h3 {...{
      "id": "hydrate"
    }}><inlineCode parentName="h3">{`hydrate`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`function hydrate(): void
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`This is only used when using the `}<inlineCode parentName="p">{`server`}</inlineCode>{` module. See `}<a parentName="p" {...{
          "href": "/usage/ssr"
        }}>{`SSR`}</a>{` for more information on
server-side rendering your hooks.`}</p>
    </blockquote>
    <p>{`A function to hydrate a server rendered component into the DOM. This is required before you can
interact with the hook, whether that is an `}<inlineCode parentName="p">{`act`}</inlineCode>{` or `}<inlineCode parentName="p">{`rerender`}</inlineCode>{` call. Effects created using
`}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` or `}<inlineCode parentName="p">{`useLayoutEffect`}</inlineCode>{` are also not run on server rendered hooks until `}<inlineCode parentName="p">{`hydrate`}</inlineCode>{` is
called.`}</p>
    <h3 {...{
      "id": "asyncutils"
    }}><inlineCode parentName="h3">{`...asyncUtils`}</inlineCode></h3>
    <p>{`Utilities to assist with testing asynchronous behaviour. See the
`}<a parentName="p" {...{
        "href": "/reference/api#async-utilities"
      }}>{`Async Utils`}</a>{` section for more details.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "act"
    }}><inlineCode parentName="h2">{`act`}</inlineCode></h2>
    <p>{`This is the same `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/test-utils.html#act"
      }}><inlineCode parentName="a">{`act`}</inlineCode>{` function`}</a>{` function that is
exported from your `}<a parentName="p" {...{
        "href": "/installation#renderer"
      }}>{`chosen renderer`}</a>{`.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "cleanup"
    }}><inlineCode parentName="h2">{`cleanup`}</inlineCode></h2>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`function cleanup(): Promise<void>
`}</code></pre>
    <p>{`Unmounts any rendered hooks rendered with `}<inlineCode parentName="p">{`renderHook`}</inlineCode>{`, ensuring all effects have been flushed. Any
callbacks added with `}<a parentName="p" {...{
        "href": "(/reference/api#addCleanup)."
      }}><inlineCode parentName="a">{`addCleanup`}</inlineCode></a>{` will also be called when
`}<inlineCode parentName="p">{`cleanup`}</inlineCode>{` is run.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Please note that this is done automatically if the testing framework you're using supports the
`}<inlineCode parentName="p">{`afterEach`}</inlineCode>{` global (like Jest, mocha and Jasmine). If not, you will need to do manual cleanups
after each test.`}</p>
    </blockquote>
    <p>{`The `}<inlineCode parentName="p">{`cleanup`}</inlineCode>{` function should be called after each test to ensure that previously rendered hooks
will not have any unintended side-effects on the following tests.`}</p>
    <h3 {...{
      "id": "skipping-auto-cleanup"
    }}>{`Skipping Auto-Cleanup`}</h3>
    <p>{`Importing `}<inlineCode parentName="p">{`@testing-library/react-hooks/dont-cleanup-after-each.js`}</inlineCode>{` in test setup files will disable
the auto-cleanup feature.`}</p>
    <p>{`For example, in `}<a parentName="p" {...{
        "href": "https://jestjs.io/"
      }}>{`Jest`}</a>{` this can be added to your
`}<a parentName="p" {...{
        "href": "https://jestjs.io/docs/configuration"
      }}>{`Jest config`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  setupFilesAfterEnv: [
    '@testing-library/react-hooks/dont-cleanup-after-each.js'
    // other setup files
  ]
}
`}</code></pre>
    <p>{`Alternatively, you can change your test to import from `}<inlineCode parentName="p">{`@testing-library/react-hooks/pure`}</inlineCode>{` (or any
of the `}<a parentName="p" {...{
        "href": "/installation#pure-imports"
      }}>{`other non-pure imports`}</a>{`) instead of the regular imports.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- import { renderHook, cleanup, act } from '@testing-library/react-hooks'
+ import { renderHook, cleanup, act } from '@testing-library/react-hooks/pure'
`}</code></pre>
    <p>{`If neither of these approaches are suitable, setting the `}<inlineCode parentName="p">{`RHTL_SKIP_AUTO_CLEANUP`}</inlineCode>{` environment
variable to `}<inlineCode parentName="p">{`true`}</inlineCode>{` before importing `}<inlineCode parentName="p">{`@testing-library/react-hooks`}</inlineCode>{` will also disable this feature.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "addcleanup"
    }}><inlineCode parentName="h2">{`addCleanup`}</inlineCode></h2>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`function addCleanup(callback: () => void | Promise<void>): (): void
`}</code></pre>
    <p>{`Add a callback to be called during `}<a parentName="p" {...{
        "href": "/reference/api#cleanup"
      }}><inlineCode parentName="a">{`cleanup`}</inlineCode></a>{`, returning a function to
remove the cleanup if is no longer required. Cleanups are called in reverse order to being added.
This is usually only relevant when wanting a cleanup to run after the component has been unmounted.`}</p>
    <p>{`If the provided callback is an `}<inlineCode parentName="p">{`async`}</inlineCode>{` function or returns a promise, `}<inlineCode parentName="p">{`cleanup`}</inlineCode>{` will wait for it to
be resolved before moving onto the next cleanup callback.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Please note that any cleanups added using `}<inlineCode parentName="p">{`addCleanup`}</inlineCode>{` are removed after `}<inlineCode parentName="p">{`cleanup`}</inlineCode>{` is called. For
cleanups that need to run with every test, it is advised to add them in a `}<inlineCode parentName="p">{`beforeEach`}</inlineCode>{` block (or
equivalent for your test runner).`}</p>
    </blockquote>
    <h2 {...{
      "id": "removecleanup"
    }}><inlineCode parentName="h2">{`removeCleanup`}</inlineCode></h2>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`function removeCleanup(callback: () => void | Promise<void>): void
`}</code></pre>
    <p>{`Removes a cleanup callback previously added with `}<a parentName="p" {...{
        "href": "/reference/api#addCleanup"
      }}><inlineCode parentName="a">{`addCleanup`}</inlineCode></a>{`. Once
removed, the provided callback will no longer execute as part of running
`}<a parentName="p" {...{
        "href": "/reference/api#cleanup"
      }}><inlineCode parentName="a">{`cleanup`}</inlineCode></a>{`.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "async-utilities"
    }}>{`Async Utilities`}</h2>
    <h3 {...{
      "id": "waitfornextupdate"
    }}><inlineCode parentName="h3">{`waitForNextUpdate`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`function waitForNextUpdate(options?: { timeout?: number | false }): Promise<void>
`}</code></pre>
    <p>{`Returns a `}<inlineCode parentName="p">{`Promise`}</inlineCode>{` that resolves the next time the hook renders, commonly when state is updated as
the result of an asynchronous update.`}</p>
    <h4 {...{
      "id": "timeout"
    }}><inlineCode parentName="h4">{`timeout`}</inlineCode></h4>
    <p><em parentName="p">{`Default: 1000`}</em></p>
    <p>{`The maximum amount of time in milliseconds (ms) to wait.`}</p>
    <h3 {...{
      "id": "waitfor"
    }}><inlineCode parentName="h3">{`waitFor`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`function waitFor(
  callback: () => boolean | void,
  options?: {
    interval?: number | false
    timeout?: number | false
  }
): Promise<void>
`}</code></pre>
    <p>{`Returns a `}<inlineCode parentName="p">{`Promise`}</inlineCode>{` that resolves if the provided callback executes without exception and returns a
truthy or `}<inlineCode parentName="p">{`undefined`}</inlineCode>{` value. It is safe to use the `}<a parentName="p" {...{
        "href": "/reference/api#result"
      }}><inlineCode parentName="a">{`result`}</inlineCode>{` of `}<inlineCode parentName="a">{`renderHook`}</inlineCode></a>{`
in the callback to perform assertion or to test values.`}</p>
    <h4 {...{
      "id": "interval"
    }}><inlineCode parentName="h4">{`interval`}</inlineCode></h4>
    <p><em parentName="p">{`Default: 50`}</em></p>
    <p>{`The amount of time in milliseconds (ms) to wait between checks of the callback if no renders occur.
Interval checking is disabled if `}<inlineCode parentName="p">{`interval`}</inlineCode>{` is not provided as a `}<inlineCode parentName="p">{`falsy`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "timeout-1"
    }}><inlineCode parentName="h4">{`timeout`}</inlineCode></h4>
    <p><em parentName="p">{`Default: 1000`}</em></p>
    <p>{`The maximum amount of time in milliseconds (ms) to wait.`}</p>
    <h3 {...{
      "id": "waitforvaluetochange"
    }}><inlineCode parentName="h3">{`waitForValueToChange`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`function waitForValueToChange(
  selector: () => any,
  options?: {
    interval?: number | false
    timeout?: number | false
  }
): Promise<void>
`}</code></pre>
    <p>{`Returns a `}<inlineCode parentName="p">{`Promise`}</inlineCode>{` that resolves if the value returned from the provided selector changes. It is
expected that the `}<a parentName="p" {...{
        "href": "/reference/api#result"
      }}><inlineCode parentName="a">{`result`}</inlineCode>{` of `}<inlineCode parentName="a">{`renderHook`}</inlineCode></a>{` will be used to select the value
for comparison.`}</p>
    <h4 {...{
      "id": "interval-1"
    }}><inlineCode parentName="h4">{`interval`}</inlineCode></h4>
    <p><em parentName="p">{`Default: 50`}</em></p>
    <p>{`The amount of time in milliseconds (ms) to wait between checks of the callback if no renders occur.
Interval checking is disabled if `}<inlineCode parentName="p">{`interval`}</inlineCode>{` is not provided as a `}<inlineCode parentName="p">{`falsy`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "timeout-2"
    }}><inlineCode parentName="h4">{`timeout`}</inlineCode></h4>
    <p><em parentName="p">{`Default: 1000`}</em></p>
    <p>{`The maximum amount of time in milliseconds (ms) to wait.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "consoleerror"
    }}><inlineCode parentName="h2">{`console.error`}</inlineCode></h2>
    <p>{`In order to catch errors that are produced in all parts of the hook's lifecycle, the test harness
used to wrap the hook call includes an
`}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/error-boundaries.html"
      }}>{`Error Boundary`}</a>{` which causes a
`}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/error-boundaries.html#component-stack-traces"
      }}>{`significant amount of output noise`}</a>{`
in tests.`}</p>
    <p>{`To keep test output clean, we patch `}<inlineCode parentName="p">{`console.error`}</inlineCode>{` when importing from
`}<inlineCode parentName="p">{`@testing-library/react-hooks`}</inlineCode>{` (or any of the `}<a parentName="p" {...{
        "href": "/installation#pure-imports"
      }}>{`other non-pure imports`}</a>{`)
to filter out the unnecessary logging and restore the original version during cleanup. This
side-effect can affect tests that also patch `}<inlineCode parentName="p">{`console.error`}</inlineCode>{` (e.g. to assert a specific error
message get logged) by replacing their custom implementation as well.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Please note that this is done automatically if the testing framework you're using supports the
`}<inlineCode parentName="p">{`beforeEach`}</inlineCode>{` and `}<inlineCode parentName="p">{`afterEach`}</inlineCode>{` global (like Jest, mocha and Jasmine). If not, you will need to do
`}<a parentName="p" {...{
          "href": "/reference/api#manually-suppress-output"
        }}>{`manual suppression`}</a>{` around the test run.`}</p>
    </blockquote>
    <h3 {...{
      "id": "disabling-consoleerror-filtering"
    }}>{`Disabling `}<inlineCode parentName="h3">{`console.error`}</inlineCode>{` filtering`}</h3>
    <p>{`Importing `}<inlineCode parentName="p">{`@testing-library/react-hooks/disable-error-filtering.js`}</inlineCode>{` in test setup files disable the
error filtering feature and not patch `}<inlineCode parentName="p">{`console.error`}</inlineCode>{` in any way.`}</p>
    <p>{`For example, in `}<a parentName="p" {...{
        "href": "https://jestjs.io/"
      }}>{`Jest`}</a>{` this can be added to your
`}<a parentName="p" {...{
        "href": "https://jestjs.io/docs/configuration"
      }}>{`Jest config`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  setupFilesAfterEnv: [
    '@testing-library/react-hooks/disable-error-filtering.js'
    // other setup files
  ]
}
`}</code></pre>
    <p>{`Alternatively, you can change your test to import from `}<inlineCode parentName="p">{`@testing-library/react-hooks`}</inlineCode>{` (or any of the
`}<a parentName="p" {...{
        "href": "/installation#pure-imports"
      }}>{`other non-pure imports`}</a>{`) instead of the regular imports.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- import { renderHook, cleanup, act } from '@testing-library/react-hooks'
+ import { renderHook, cleanup, act } from '@testing-library/react-hooks/pure'
`}</code></pre>
    <p>{`If neither of these approaches are suitable, setting the `}<inlineCode parentName="p">{`RHTL_DISABLE_ERROR_FILTERING`}</inlineCode>{` environment
variable to `}<inlineCode parentName="p">{`true`}</inlineCode>{` before importing `}<inlineCode parentName="p">{`@testing-library/react-hooks`}</inlineCode>{` will also disable this feature.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Please note that this may result in a significant amount of additional logging in your test
output.`}</p>
    </blockquote>
    <h3 {...{
      "id": "manually-suppress-output"
    }}>{`Manually suppress output`}</h3>
    <p>{`If you are using `}<a parentName="p" {...{
        "href": "/installation#pure-imports"
      }}>{`a pure import`}</a>{`, you are running your tests in an
environment that does not support `}<inlineCode parentName="p">{`beforeEach`}</inlineCode>{` and `}<inlineCode parentName="p">{`afterEach`}</inlineCode>{`, or if the automatic suppression is
not available to you for some other reason, then you can use the `}<inlineCode parentName="p">{`suppressErrorOutput`}</inlineCode>{` export to
manually start and top suppress the output:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { renderHook, suppressErrorOutput } from '@testing-library/react-hooks/pure'

test('should handle thrown error', () => {
  const restoreConsole = suppressErrorOutput()

  try {
    const { result } = renderHook(() => useCounter())
    expect(result.error).toBeDefined()
  } finally {
    restoreConsole()
  }
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      